import { Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home'
import { Terms } from './pages/Terms'
import { Policy } from './pages/Policy'
import { Orders } from './pages/Orders'
import { DefaultLayout } from './DefaultLayout'

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Policy />} />
        <Route path="/orders" element={<Orders />} />
      </Route>
    </Routes>
  )
}
