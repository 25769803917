export interface Province {
  name: string
  value: string
}

export const PROVINCES: Province[] = [
  {
    name: 'Luanda',
    value: 'luanda',
  },
  {
    name: 'Benguela',
    value: 'benguela',
  },
  {
    name: 'Huíla',
    value: 'huila',
  },
]
