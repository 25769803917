import { ReactNode, createContext, useState } from 'react'
import { api } from '../lib/axios'

interface CartContextProps {
  priceTotal: number
  quantityItems: number
  coupons: any[]
  onAdd: () => void
  onRemove: () => void
  fetchCoupons: () => void
}

interface CartContextProviderProps {
  children: ReactNode
}

interface CouponProps {
  id: string
  name: string
  discount: number
  isValid: boolean
}

export const CartContext = createContext({} as CartContextProps)

const PRICE_TV = Number(process.env.REACT_APP_API_PRICE)

const IS_PROMOTION = process.env.REACT_APP_API_IS_PROMOTION === 'true'

const PRICE_TV_REAL = Number(process.env.REACT_APP_API_PRICE_REAL)

const PRICE_TV_CHOOSE = IS_PROMOTION ? PRICE_TV : PRICE_TV_REAL

export function CartContextProvider({ children }: CartContextProviderProps) {
  const [quantityItems, setQuantityItems] = useState(1)
  const priceTotal = quantityItems * PRICE_TV_CHOOSE

  const [coupons, setCoupons] = useState<CouponProps[]>([])

  async function fetchCoupons() {
    const response = await api.get('/coupon')
    setCoupons(response.data.coupon)
  }

  function onAdd() {
    setQuantityItems((prev) => prev + 1)
  }

  function onRemove() {
    if (quantityItems === 1) {
      return
    }
    setQuantityItems((prev) => prev - 1)
  }

  return (
    <CartContext.Provider
      value={{
        priceTotal,
        quantityItems,
        onAdd,
        onRemove,
        coupons,
        fetchCoupons,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
