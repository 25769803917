import { Footer } from '../components/Footer'
import { Header } from '../components/Header'

import { Outlet } from 'react-router-dom'

export function DefaultLayout() {
  return (
    <div className="w-screen overflow-hidden">
      <Header />
      <div>
        <Outlet />
      </div>

      <Footer />
    </div>
  )
}
