// import imgWeb_1920 from '../img/update_price/web_1920x720.jpg'
import imgWeb_2000 from '../img/update_price/web_2000x1515.jpg'

export function Modal(props: any) {
  return (
    <section style={{
        backgroundColor: '#000000a1',
        width: '100%',
        height: '100%',
        zIndex: 10000,
        position: 'fixed', 
        top: 0,
    }}>
      <div style={{
            position: 'fixed', 
            width: '100%', 
            top: '10%',
            bottom: 'auto',
            zIndex: 10000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

        }}>
          <div  className="w-[380px] md:w-[600px] lg:w-[750px]" style={{ display: 'flex', justifyContent: 'center', position: 'relative'}}>
            <img src={imgWeb_2000} alt=""  className="w-[380px] md:w-[600px] lg:w-[750px]"/>
            <div onClick={props.handleCloseModal} style={{top: '-10px', right: '-10px', height: '30px', width: '30px', textAlign: 'center', borderRadius: '50%', backgroundColor: '#000', color: '#01a7d3', border: '1px solid #01a7d3', position: 'absolute', cursor: 'pointer'}}>x</div>
          </div>
      </div>
    </section>
  )
}
