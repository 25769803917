export function Policy() {
  return (
    <div className="bg-black">
      <div className="bg-gray-800">
        <h1 className="text-4xl text-zinc-200 py-9 text-center ">
          Politica de Privacidade
        </h1>
      </div>
      <div className="text-zinc-200 mx-auto w-9/12 text-justify py-8">
        <p className="mt-5">
          <strong className="inline">A CETIM TECNOLOGIA, S.A.</strong>,
          abreviadamente designada por “CETIM” está empenhada em proteger os
          dados pessoais dos Clientes/Utilizadores, tendo, neste contexto,
          elaborado a presente Política, a qual tem subjacente o seu compromisso
          em respeitar a privacidade dos seus utilizadores/clientes e informar
          como a sua informação é tratada.
        </p>

        <p className="mt-5">
          <strong>O QUE ABRANGE ESTA POLÍTICA DE PROTEÇÃO DE DADOS ?</strong>{' '}
          <br />
          Esta Política de Proteção de Dados aplica-se exclusivamente à recolha
          e tratamento de dados pessoais relativamente aos quais a CETIM é
          responsável pelo respetivo tratamento, no âmbito dos produtos
          disponibilizados aos seus Clientes/Utilizadores. No website da NOAH
          poderão estar incluídos links de acesso a outros websites que são
          alheios à NOAH. A disponibilização de tais links é efetuada de boa-fé,
          não podendo a NOAH ser responsabilizada pela recolha e tratamento de
          dados pessoais efetuados através desses websites, nem assumindo a NOAH
          qualquer responsabilidade relativamente a tais websites, nomeadamente
          quanto à respetiva exatidão, credibilidade e funcionalidades
          disponibilizadas nos mesmos.
        </p>

        <p className="mt-5">
          <strong>
            O QUE SÃO DADOS PESSOAIS E PARA QUE FINS SÃO UTILIZADOS ?
          </strong>{' '}
          <br />
          Dados pessoais são qualquer informação relativa a uma pessoa singular
          identificada ou identificável. O titular dos dados deverá declarar
          expressamente que autoriza a recolha ou atualização dos seus dados de
          contacto (nome completo, email, morada, morada de entrega, morada de
          faturação, contacto telefónico e data de nascimento), para integração
          nos ficheiros da NOAH com a finalidade o envio de campanhas,
          promoções, publicidade e notícias sobre produtos e gestão das
          encomendas efetuadas no website da NOAH. Os dados pessoais recolhidos
          são indispensáveis para o envio dos produtos adquiridos na loja online
          e, em caso de falta ou insuficiência dos mesmos, a NOAH não poderá
          disponibilizar o produto em causa. O consentimento expresso deverá ser
          efetuado quando realizar o Registo de utilizador na Loja On Line e
          após receber o email no website da NOAH.
        </p>

        <p className="mt-5">
          <strong>QUEM É O RESPONSÁVEL PELO TRATAMENTO DE DADOS ?</strong>
          <br />A entidade responsável pelo tratamento dos dados pessoais é a
          CETIM que determina as finalidades e os meios de tratamento dos
          mesmos. Para este efeito, caso o titular dos dados pessoais necessite
          de entrar em contacto com o responsável pelo tratamento de dados,
          poderá fazê-lo através dos contactos abaixo indicados: …….@...........
          +244……………
        </p>

        <p className="mt-5">
          <strong>COMO RECOLHEMOS OS SEUS DADOS PESSOAIS ?</strong>
          <br />
          Os dados pessoais recolhidos podem ser tratados informaticamente e de
          forma automatizada ou não automatizada, garantindo em todos os casos o
          estrito cumprimento da legislação de proteção de dados pessoais, sendo
          armazenados em bases de dados específicas, criadas para o efeito e, em
          situação alguma, os dados recolhidos serão utilizados para outra
          finalidade que não seja aquela para a qual foram recolhidos ou dado o
          consentimento por parte do titular dos dados.
        </p>

        <p className="mt-5">
          <strong>POR QUANTO TEMPO CONSERVAMOS OS SEUS DADOS PESSOAIS ?</strong>
          <br />
          Os dados pessoais são armazenados e conservados apenas pelo período
          mínimo necessário para a prossecução das finalidades que motivaram a
          sua recolha ou o seu posterior tratamento, nos termos definidos na
          lei.
        </p>

        <p className="mt-5">
          <strong>QUAIS SÃO OS SEUS DIREITOS ENQUANTO TITULAR ?</strong> <br />
          Enquanto titulares dos dados pessoais, é garantido aos
          Clientes/Utilizadores, a qualquer momento, o direito de acesso,
          retificação, atualização, limitação e eliminação dos seus dados
          pessoais. Sem prejuízo do disposto na Lei de Protecção de Dados, o
          titular dos dados pessoais poderá fazê-lo mediante pedido por escrito,
          dirigido ao respetivo Responsável pelo tratamento, através dos
          contactos disponibilizados para o efeito no presente documento.
        </p>

        <p className="my-5">
          <strong>
            EM QUE CIRCUNSTÂNCIAS EXISTE COMUNICAÇÃO DE DADOS A OUTRAS ENTIDADES
            (TERCEIROS E SUBCONTRATADOS) ?
          </strong>{' '}
          <br />A CETIM, no âmbito da sua actividade, poderá recorrer a
          terceiros para a prestação do serviço de entrega/ transporte. Por
          vezes, a prestação destes serviços implica o acesso, por estas
          entidades, a dados pessoais dos Clientes/Utilizadores. Quando tal
          sucede, a CETIM toma as medidas adequadas, de forma a assegurar que as
          entidades que tenham acesso aos dados são reputadas e oferecem as mais
          elevadas garantias a este nível, o que fica devidamente consagrado e
          acautelado contratualmente entre a CETIM e a(s) terceira(s)
          entidade(s). Em qualquer dos casos, a CETIM permanece responsável
          pelos dados pessoais que lhe sejam disponibilizados.
        </p>
      </div>
    </div>
  )
}
