import { CaretLeft, Minus, Plus } from 'phosphor-react'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from '../contexts/CartContext'
import { CreateOrderForm } from '../components/CreateOrderForm'
import { Carousel } from '../components/Carousel'

const PRICE_TV_REAL = Number(process.env.REACT_APP_API_PRICE_REAL)

const IS_PROMOTION = process.env.REACT_APP_API_IS_PROMOTION === 'true'

export function Orders() {
  const { priceTotal, quantityItems, onAdd, onRemove } = useContext(CartContext)
  const [page, setPage] = useState(1)

  function handleChangePage() {
    page === 1 ? setPage(2) : setPage(1)
  }

  return (
    <section className="min-h-screen grid grid-cols-1 lg:grid-cols-2">
      <Carousel />
      <div className="h-full w-full flex flex-col justify-center p-6">
        <header>
          <Link
            to="/"
            className="inline-flex items-center gap-2 text-gray-700 hover:text-gray-800"
          >
            <CaretLeft size={18} />
            Voltar para home
          </Link>
        </header>

        <div>
          {page === 1 ? (
            <section>
              <div className="mt-8">
                <h3 className="text-3xl font-semibold text-gray-800">
                  TV Genesis
                </h3>
                <span className="mt-4 block text-lg text-gray-600">
                  Smart TV 55 Polegadas
                </span>
                <p className="mt-4 text-gray-900 text-sm font-light">
                  Agora é mais fácil desfrutar do momento de lazer que tanto
                  merece! Assista a partir das suas aplicações favoritas,
                  reproduza músicas e jogue em qualquer dispositivo.
                </p>

                <p className="mt-4 text-orange-500">
                  Encomendas disponíveis a partir de 5 unidades em Benguela e
                  Huíla.
                </p>

                <ul className="mt-4 list-disc list-inside">
                  <li className="text-base text-gray-600 mt-2">
                    Memória RAM(1.5GB)
                  </li>
                  <li className="text-base text-gray-600 mt-2">
                    2 Entradas USB
                  </li>
                  <li className="text-base text-gray-600 mt-2">
                    2 Entradas VGA
                  </li>
                  <li className="text-base text-gray-600 mt-2">HDMI</li>
                  <li className="text-base text-gray-600 mt-2">Entrada RJ45</li>
                </ul>
              </div>

              <div className="flex justify-between items-center mt-6 flex-wrap">
                <div>
                  <label htmlFor="quantity" className="sr-only">
                    {' '}
                    Quantity{' '}
                  </label>

                  <div className="flex items-center rounded">
                    <button
                      type="button"
                      onClick={onRemove}
                      className="w-10 h-10 rounded-full leading-10 text-gray-600 transition hover:opacity-75 bg-gray-400 flex items-center justify-center"
                    >
                      <Minus weight="bold" />
                    </button>

                    <input
                      type="number"
                      id="quantity"
                      min={1}
                      value={quantityItems}
                      onChange={onAdd}
                      className="h-10 w-16 border-transparent text-center font-medium [-moz-appearance:_textfield] sm:text-sm [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
                    />

                    <button
                      type="button"
                      onClick={onAdd}
                      className="w-10 h-10 rounded-full leading-10 text-gray-100 transition hover:bg-gray-900 bg-gray-800 flex items-center justify-center"
                    >
                      <Plus weight="bold" />
                    </button>
                  </div>
                </div>

                {IS_PROMOTION ? (
                  <>
                    <strong className="text-xl font-normal line-through mt-1">
                      {Intl.NumberFormat('pt-AO', {
                        style: 'currency',
                        currency: 'AOA',
                      }).format(PRICE_TV_REAL)}
                    </strong>
                    <strong className="text-xl font-bold mt-1">
                      {Intl.NumberFormat('pt-AO', {
                        style: 'currency',
                        currency: 'AOA',
                      }).format(priceTotal)}
                    </strong>
                  </>
                ) : (
                  <strong className="text-xl font-bold mt-1">
                    {Intl.NumberFormat('pt-AO', {
                      style: 'currency',
                      currency: 'AOA',
                    }).format(PRICE_TV_REAL)}
                  </strong>
                )}
              </div>

              <div className="mt-6">
                <button
                  type="button"
                  onClick={handleChangePage}
                  className="bg-gray-800 text-gray-100 w-full py-2 rounded hover:bg-gray-700"
                >
                  Encomendar
                </button>
              </div>
            </section>
          ) : (
            <CreateOrderForm onPrevious={handleChangePage} />
          )}
        </div>
      </div>
    </section>
  )
}
