export function Terms() {
  return (
    <>
      <div className=" bg-black">
        <div className=" bg-gray-800 p-7 mb-10 ">
          <h1 className="text-4xl text-zinc-200 py-9 text-center">
            TERMOS E CONDIÇÕES
          </h1>
        </div>
        <div className="text-zinc-200 mx-auto w-9/12 text-justify py-8">
          <p className="mt-5">
            <strong className="inline">1. INTRODUÇÃO</strong> <br />
            Os termos e condições regulam o acesso e utilização do website
            www.NOAH.ao e www.NOAH.co.ao, da responsabilidade da CETIM
            TECNOLOGIA, S.A, abreviadamente designada por CETIM, com sede em
            Luanda, Distrito Urbano e Bairro Talatona, rua do Centro de
            Convenções, Edifício Masuika. 7.º andar, Bloco B, com o NIF
            5417508853, doravante CETIM, e actua, entre outras, na área das
            TICs, fornecendo serviços personalizados, desenvolvendo soluções
            avançadas de software e hardware, levando em consideração as novas
            tendências tecnológicas do mercado.
          </p>

          <p className="mt-5">
            O acesso e utilização deste website atribui a quem o utiliza a
            condição de utilizador e implica que o mesmo, reconheça e aceite
            plenamente as condições de acesso e uso: a) Os conteúdos
            disponibilizados neste Website foram desenvolvidos pela CETIM; b) Os
            dados contidos neste site são apenas para fins informativos; c) O
            Utilizador reconhece que todas as informações e conteúdos
            disponibilizados neste website, incluindo imagens, vídeos, textos,
            logótipos, desenhos gráficos e/ou qualquer outro documento neles
            contido, constituem propriedade industrial ou intelectual da CETIM
            e/ou de terceiros que consentem a sua inclusão; d) Os Utilizadores
            estão expressamente proibidos de copiar, armazenar, reproduzir,
            alterar, transmitir, divulgar, comercializar ou fornecer as
            informações contidas neste Site, exceto para fins particulares e
            gratuitamente. Qualquer informação disponibilizada deverá sempre
            referir-se à fonte; e) Ao aceder a outros websites através de links
            fornecidos pela CETIM, o Utilizador reconhece que a CETIM não exerce
            qualquer controlo sobre o conteúdo desses websites e, portanto, não
            poderá ser responsabilizada por quaisquer consequências advindas do
            acesso aos mesmos, seja por que motivo for; f) Os conteúdos e
            informações disponibilizados neste website podem, a qualquer
            momento, ser sujeitos a reajustes e alterações pela CETIM, sem aviso
            prévio.
          </p>

          <p className="mt-5">
            Estes termos e condições podem a qualquer momento ser modificados.
            Sempre que alterações substanciais forem feitas, o utilizador será
            avisado e será pedido uma nova leitura e aceitação dos termos e
            condições.
          </p>

          <p className="mt-5">
            <strong>
              2. ACEITAÇÃO DOS TERMOS E CONDIÇÕES DE UTILIZAÇÃO DO WEBSITE
            </strong>{' '}
            <br />
            Ao subscrever os produtos, serviços ou aceder ao website e os seus
            conteúdos, o utilizador deve ler e aceitar os Termos e Condições
            previamente descritos.
          </p>

          <p className="mt-5">
            <strong>3. PROTECÇÃO DE DADOS PESSOAIS</strong>
            <br />A CETIM adoptou as medidas técnicas e processuais adequadas
            para preservar a segurança dos serviços disponíveis no website,
            assegurando a confidencialidade e integridade dos dados pessoais
            partilhados pelos utilizadores. Recomenda-se a consulta da Política
            de Privacidade, que constitui parte dos termos e condições, para
            melhor entender como o Website da NOAH recolhe e utiliza os dados
            pessoais dos utilizadores assim como as respectivas finalidades.
          </p>

          <p className="mt-5">
            <strong>4. COOKIES</strong>
            <br />
            Os cookies são ficheiros que ficam armazenados no seu computador,
            tablet ou smartphone através do navegador (browser), quando visita o
            nosso site pela primeira vez. Os cookies retêm apenas informação
            relacionada com as suas preferências, não incluindo, como tal, os
            seus dados pessoais. A CETIM utiliza cookies para: a) Conhecer os
            nossos visitantes e assim melhorar a sua experiência de navegação no
            site; b) Personalizar a informação das páginas web que visitam com
            conteúdos de interesse; c) Sugerir a mudança de idioma do site para
            que tenha acesso a conteúdo na sua língua nativa; d) Propor a
            seleção do site com produtos e serviços personalizados para a região
            onde se encontra; e) Segmentar a nossa oferta e disponibilizar
            campanhas de acordo com os interesses de cada um dos nossos
            visitantes. Com a ajuda destes cookies, a CETIM potência a criação
            de um relacionamento com os visitantes e clientes de forma a
            melhorar a sua experiência e recolher dados estatísticos para
            continuarmos a aperfeiçoar a sua navegação no site e os nossos
            produtos e serviços. Para navegar neste site, terá de aceitar a
            instalação destes cookies no seu browser. Contudo, as preferências
            dos cookies podem ser limpas do seu browser a qualquer momento.
          </p>

          <p className="mt-5">
            <strong>5. DIREITOS DE AUTOR</strong>
            <br />
            Os textos, imagens, gráficos, downloads, audio, vídeos, animação, e
            todas as outras informações juntamente com a forma como são
            representadas graficamente no website e nos nossos produtos, estão
            todos sujeitos aos direitos de autor da CETIM. A CETIM não concede
            licença ou direitos de propriedade sobre os Materiais e Informação,
            pelo que não tem o direito de reproduzi-los para qualquer outro fim
            que não a utilização pessoal. Nem o website ou os produtos, nem
            partes do mesmo podem ser copiados, distribuídos, modificados ou
            colocados em outros locais, sem autorização prévia por escrito da
            CETIM.
          </p>

          <p className="mt-5">
            <strong>6. LINKS PARA PÁGINAS DE TERCEIROS</strong> <br />A NOAH
            poderá disponibilizar links para páginas de terceiros no seu website
            e produtos. Estes sites podem não pertencer ou ser operados ou
            controlados pela CETIM e, por esta razão, a não se responsabiliza
            pelo seu conteúdo. A utilização destes links é da sua
            responsabilidade.
          </p>

          <p className="mt-5">
            <strong>7. RESPONSABILIDADE DOS CONTEÚDOS DO WEBSITE</strong> <br />
            As informações existentes neste website são fornecidas pela CETIM no
            estado em que se encontram e, até à máxima extensão permitida pela
            lei aplicável, são fornecidas sem qualquer tipo de garantia,
            expressa ou implícita. Embora as informações sejam tidas como
            corretas, podem incluir erros ou imprecisões, e sempre que
            alteradas, será novamente avisado e será solicitada a aceitação dos
            termos e condições de utilização deste website.
          </p>

          <p className="mt-5">
            A CETIM não representa nem garante que as funções ou aplicações
            contidas neste website sejam contínuas ou não tenham erros. A CETIM
            não garante que o servidor e/ou o website não contenham vírus ou
            outros componentes prejudiciais. A CETIM não garante que os
            materiais deste website sejam adequados para utilização noutros
            locais ou por outros públicos, nomeadamente menores de idade ou para
            fins académicos. É proibido o acesso a materiais à partir de um
            território em que os conteúdos sejam ilegais.
          </p>

          <p className="mt-5">
            <strong>
              8. NOTA ESPECÍFICA SOBRE OS PRODUTOS DISPONÍVEIS NESTE WEBSITE
            </strong>{' '}
            <br />
          </p>

          <p>
            Qualquer produto disponibilizado para download, subscrição ou compra
            está protegido por direitos de autor da CETIM e / ou de seus
            fornecedores. O uso do Produto é regido pelos termos do contrato de
            licença de utilização do respetivo produto.
          </p>
          <p>
            Qualquer reprodução ou redistribuição do Produto que não esteja de
            acordo com o Contrato de Licença é expressamente proibida por lei e
            pode resultar em severas penalidades civis e criminais. Os
            infratores serão processados na máxima extensão possível.
          </p>

          <p className="mt-5">
            <strong>9. ALTERAÇÃO</strong> <br />A CETIM reserva-se ao direito de
            actualizar ou alterar, a qualquer momento, o website ou qualquer dos
            serviços que o integram sem aviso prévio
          </p>

          <p className="mt-5">
            <strong>10. DURAÇÃO</strong> <br />A CETIM reserva-se ao direito de
            suspender ou descontinuar, a qualquer momento, o website ou qualquer
            dos serviços que o integram sem aviso prévio.
          </p>

          <p className="mt-5">
            <strong>11. RENÚNCIA</strong> <br />A CETIM renuncia a qualquer
            responsabilidade por eventuais transtornos que possam ocorrer devido
            a erros do sistema ou falha (temporária ou permanente) do website,
            das aplicações ou de outras ferramentas. Até à máxima extensão
            possível ao abrigo da lei aplicável, a CETIM não se responsabilizará
            por quaisquer danos resultantes da utilização, ou da impossibilidade
            de utilização, do website
          </p>

          <p className="mt-5">
            <strong>12. MENORES DE 16 ANOS</strong> <br />
            Os nossos websites e produto ou aplicações não se destinam a
            indivíduos menores de 16 anos de idade. Solicitamos que os menores
            de 16 anos não forneçam informações pessoais à CETIM através de
            qualquer website, produto ou aplicação. Se detetarmos que recolhemos
            informações pessoais de um menor de 16 anos, tomaremos as medidas
            necessárias para eliminar as informações assim que possível. Para
            qualquer informação adicional deverá entrar em contacto connosco em
            Contacte-nos.
          </p>

          <p className="my-5">
            <strong>13. LEI APLICÁVEL</strong> <br />
            Os presentes Termos de Utilização são regidos e elaborados de acordo
            com a Lei da Proteção de Dados pessoais (Lei 22/11 de 17 de Junho) e
            demais legislação aplicável.
          </p>
        </div>
      </div>
    </>
  )
}
