import { Swiper, SwiperSlide } from 'swiper/react'

import { Pagination } from 'swiper'

import tv1 from '../img/tvs/__pic-1.png'
import tv2 from '../img/tvs/__pic-2.png'
import tv3 from '../img/tvs/__pic-3.png'

import 'swiper/css'
import 'swiper/css/pagination'

export function Carousel() {
  return (
    <div className="bg-gray-50 p-4 h-full flex flex-col justify-center">
      <div>
        <Swiper
          loop={true}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper2"
        >
          <SwiperSlide className="flex justify-center items-center my-auto">
            <div className="flex flex-col items-center justify-center">
              <img src={tv1} alt="" className="w-auto h-auto object-cover" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="flex justify-center items-center my-auto">
            <div className="flex flex-col items-center justify-center">
              <img src={tv2} alt="" className="w-auto h-auto object-cover" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="flex justify-center">
            <div className="flex flex-col items-center justify-center">
              <img src={tv3} alt="" className="w-auto h-auto object-cover" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}
