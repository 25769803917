import logoNoah from '../img/noahlogo.png'
import { Link } from 'react-router-dom'
import { FacebookLogo, InstagramLogo, LinkedinLogo } from 'phosphor-react'

export function Footer() {
  return (
    <footer className="py-16 bg-slate-800/100">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-4 items-center lg:items-start justify-center lg:justify-between px-8 md:px-6">
        <div className="flex flex-col gap-4">
          <Link to="/">
            <img src={logoNoah} alt="" className="w-28" />
          </Link>

          <div className="flex gap-2">
            <a
              href="https://www.facebook.com/noahangola"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookLogo size={28} className="text-slate-500" />
            </a>
            <a
              href="https://instagram.com/noah_angola?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <InstagramLogo size={28} className="text-slate-500" />
            </a>
            <a
              href="https://www.linkedin.com/company/noah-angola/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinLogo size={28} className="text-slate-500" />
            </a>
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-4">
            <Link
              to="/privacy"
              className="text-slate-400 mr-4 hover:underline md:mr-6 text-center lg:text-left"
            >
              Politica de Privacidade
            </Link>
            <Link
              to="/terms"
              className="text-slate-400 mr-4 hover:underline md:mr-6 text-center lg:text-left"
            >
              Termos e Condições
            </Link>
            <p className="text-slate-400 text-center lg:text-left">
              Contacto de suporte:{' '}
              <strong className="text-white">942 990 750</strong>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
