import React, { useContext, useRef, useState } from 'react'

import * as RadioGroup from '@radix-ui/react-radio-group'
import { CheckCircle, XCircle } from 'phosphor-react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { CartContext } from '../contexts/CartContext'
import { Input } from './Input'
import { api } from '../lib/axios'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'

import { useNavigate } from 'react-router-dom'
import { PROVINCES } from '../utils/global'

interface CreateOrderFormProps {
  onPrevious: () => void
}

const API_RECAPTCHA = String(process.env.REACT_APP_API_KEY_RECAPTCHA)

const createOrderSchema = z.object({
  name: z.string().nonempty({ message: 'Nome do cliente obrigatório' }),
  phone: z.string().nonempty({ message: 'Número de telefone obrigatório' }),
  email: z
    .string()
    .email({ message: 'Formato de e-mail inválido' })
    .nonempty({ message: 'E-mail obrigatório' }),
  address: z.string().nonempty({ message: 'Endereço obrigatório' }),
  nif: z.string().nullable(),
  paymentMethod: z.enum(['Transfer', 'Money'], {
    required_error: 'Deve selecionar um método de pagamento',
  }),
  cupon: z.string().nullable(),
  attach: z.instanceof(FileList).nullable(),
  province: z.string().nonempty({ message: 'Província obrigatória' }),
})

export type CreateOrderInput = z.infer<typeof createOrderSchema>

export function CreateOrderForm({ onPrevious }: CreateOrderFormProps) {
  const { priceTotal, quantityItems } = useContext(CartContext)

  const [price, setPrice] = useState(priceTotal)
  const [discount, setDiscount] = useState(0)
  const [couponValidation, setCouponValidation] = useState<
    'valid' | 'invalid' | 'noValue' | 'processing'
  >('noValue')

  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateOrderInput>({
    resolver: zodResolver(createOrderSchema),
    defaultValues: {
      paymentMethod: 'Transfer',
      province: 'luanda',
    },
  })

  const [selectedProvince, setSelectedProvince] = useState('luanda')

  const [capValue, setCapValue] = useState('')
  const referenceRecaptcha = useRef<ReCAPTCHA>(null)

  const [methodPayment, setMethodPayment] = useState<'Transfer' | 'Money'>(
    'Transfer',
  )

  const coupon = watch('cupon')

  const isOutOfTown = selectedProvince !== 'luanda'

  async function handleCreateOrder(data: CreateOrderInput) {
    if (!capValue) return toast.error('Por favor, confirme que não é um Robo!')

    const { name, email, address, cupon, nif, phone, attach } = data

    const file = attach ? attach[0] : null

    if (methodPayment === 'Transfer' && file === undefined) {
      return toast.error('Deve seleccionar um comprovativo!')
    }

    const base64File = file ? await convertFileToBase64(file) : null

    const newOrder = {
      name,
      email,
      address,
      cupon,
      discount,
      nif,
      paymentMethod: methodPayment,
      phone,
      quantityItems,
      price,
      province: selectedProvince,
      priceTotal,
      attach: base64File,
      recaptcha: capValue,
    }

    try {
      const response = await api.post('/order', newOrder)
      if (response.status === 201) {
        reset()
        setDiscount(0)
        setPrice(priceTotal)
        setCouponValidation('noValue')
        toast.success(
          'Produto encomendado com sucesso. Verifique a sua caixa de e-mail dentro de alguns segundos.',
        )
      } else {
        return toast.error(response.data.message)
      }

      navigate('/')
    } catch (err) {
      toast.error('Não foi possível encomendar o produto')
    }
  }

  const convertFileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = () => {
        if (reader.result) {
          const base64String = reader.result?.toString()
          resolve(base64String)
        } else {
          reject(new Error('Erro ao converter o arquivo para base64'))
        }
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })
  }

  async function checkCoupon(name: string) {
    try {
      const response = await api.get(`/coupon/${name}`)
      const isCouponAlreadyExists = response.data.coupon

      setCouponValidation('processing')

      const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms))

      if (!isCouponAlreadyExists) {
        await delay(3000)
        setCouponValidation('invalid')

        return false
      }

      if (isCouponAlreadyExists.isValid === false) {
        await delay(3000)
        setCouponValidation('invalid')

        return false
      }

      await delay(3000)

      setDiscount(isCouponAlreadyExists.discount)
      setPrice(priceTotal - (isCouponAlreadyExists.discount / 100) * priceTotal)
      setCouponValidation('valid')
      return isCouponAlreadyExists
    } catch (e) {
      return toast.error(String(e))
    }
  }

  function onChangeProvince(provinceSelected: string) {
    setSelectedProvince(provinceSelected)

    if (provinceSelected !== 'luanda') {
      setMethodPayment('Transfer')
    }
  }

  function onChangeRecaptcha(recaptchaSelected: any) {
    setCapValue(recaptchaSelected)
  }

  return (
    <form onSubmit={handleSubmit(handleCreateOrder)}>
      <h3 className="text-3xl font-semibold text-gray-800 my-4">
        Dados da encomenda
      </h3>

      <div className="grid grid-cols-1 gap-2">
        <Input idFor="name" label="Nome completo*" {...register('name')} />

        {errors.name && (
          <span className="text-xs font-medium text-red-400">
            {errors.name.message}
          </span>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="flex flex-col">
          <Input idFor="phone" label="Telefone*" {...register('phone')} />

          {errors.phone && (
            <span className="text-xs font-medium text-red-400">
              {errors.phone.message}
            </span>
          )}
        </div>

        <div className="flex flex-col">
          <Input
            idFor="email"
            label="E-mail"
            type="email"
            {...register('email')}
          />

          {errors.email && (
            <span className="text-xs font-medium text-red-400">
              {errors.email.message}
            </span>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="province" className="text-sm text-gray-600">
            Província*
          </label>
          <Controller
            control={control}
            name="province"
            render={({ field }) => {
              return (
                <select
                  onChange={(event) => onChangeProvince(event.target.value)}
                  value={selectedProvince}
                  id="province"
                  className="border focus:border-blue-300 rounded-lg px-4 py-2 text-sm outline-none"
                >
                  {PROVINCES.map((province) => {
                    return (
                      <option key={province.name} value={province.value}>
                        {province.name}
                      </option>
                    )
                  })}
                </select>
              )
            }}
          />

          {errors.province && (
            <span className="text-xs font-medium text-red-400">
              {errors.province.message}
            </span>
          )}
        </div>
        <div className="flex flex-col">
          <Input
            idFor="address"
            label="Endereço de entrega*"
            {...register('address')}
          />

          {errors.address && (
            <span className="text-xs font-medium text-red-400">
              {errors.address.message}
            </span>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="flex flex-col">
          <Input idFor="nif" label="NIF" {...register('nif')} />
        </div>
      </div>

      <div className="border-b mt-2 py-2">
        <strong className="font-medium text-sm">Método de pagamento</strong>

        <div className="mt-2">
          <legend className="sr-only">Método de pagamento</legend>

          <div className="">
            <Controller
              control={control}
              name="paymentMethod"
              render={({ field }) => {
                return (
                  <RadioGroup.Root
                    onValueChange={field.onChange}
                    value={methodPayment}
                    className="grid grid-cols-1 md:grid-cols-2 gap-3"
                  >
                    <RadioGroup.Item
                      value="Transfer"
                      className="border py-2 rounded-lg radio"
                      onClick={() => setMethodPayment('Transfer')}
                    >
                      Transferência
                    </RadioGroup.Item>
                    {!isOutOfTown && (
                      <RadioGroup.Item
                        value="Money"
                        className="border py-2 rounded-lg radio"
                        onClick={() => setMethodPayment('Money')}
                      >
                        Pagamento na hora
                      </RadioGroup.Item>
                    )}
                  </RadioGroup.Root>
                )
              }}
            />
          </div>

          {methodPayment === 'Transfer' ? (
            <>
              <div className="mt-2">
                <Input
                  idFor="attach"
                  label="Comprovativo"
                  type="file"
                  {...register('attach')}
                />
              </div>
              <div className=" mt-4 block text-gray-500">
                <strong className="text-xs">Coordenadas bancárias: </strong>
                <p className="text-xs font-light">
                  Titular - Cetim Tecnologia SA Nº de conta:{' '}
                  <strong className="font-semibold">191969798 10 004</strong> |
                  IBAN:{' '}
                  <strong className="font-semibold">
                    AO06 0055 0000 9196 9798 1049 5
                  </strong>{' '}
                  | Banco Millennium Atlântico
                </p>
              </div>
            </>
          ) : (
            <span className="text-xs font-light mt-4 block text-gray-500">
              Pagamento no momento da entrega.
            </span>
          )}
        </div>
      </div>

      <div className="mt-2 py-2">
        <strong className="font-medium text-sm">Cupão</strong>

        <div className="flex flex-col md:flex-row items-center gap-4 mt-2">
          <input
            type="text"
            className="border flex-1 w-full py-2 px-4 rounded-md outline-none text-sm text-gray-800 focus:border-blue-200"
            {...register('cupon')}
          />

          {couponValidation === 'noValue' ? (
            <button
              type="button"
              disabled={!coupon}
              onClick={() => checkCoupon(coupon!)}
              className="bg-orange-500 px-4 py-2 rounded-md text-sm font-medium hover:bg-orange-600 text-gray-50"
            >
              Validar cupão
            </button>
          ) : couponValidation === 'invalid' ? (
            <button
              type="button"
              disabled={!coupon}
              onClick={() => checkCoupon(coupon!)}
              className="bg-red-500 flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 text-gray-50 disabled:bg-red-500/60 disabled:cursor-not-allowed"
            >
              Inválido
              <XCircle size={20} />
            </button>
          ) : couponValidation === 'processing' ? (
            <button
              type="button"
              disabled
              className="bg-orange-500/60 px-4 py-2 rounded-md text-sm font-medium text-gray-50"
            >
              Processando...
            </button>
          ) : (
            couponValidation === 'valid' && (
              <button
                type="button"
                disabled={!coupon}
                onClick={() => checkCoupon(coupon!)}
                className="bg-green-500 flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium hover:bg-green-500/60 disabled:bg-green-300 disabled:cursor-not-allowed text-gray-50"
              >
                Válido
                <CheckCircle size={20} />
              </button>
            )
          )}
        </div>
      </div>

      <div className="border-t mt-4 py-2">
        <strong className="font-medium text-sm">Resumo do pedido</strong>

        <div className="mt-4 flex flex-col gap-4">
          <div className="flex justify-between">
            <span className="font-light text-gray-600">Produto(s)</span>
            <strong className="font-medium text-lg">{quantityItems}</strong>
          </div>
          <div className="flex justify-between">
            <span className="font-light text-gray-600">Desconto</span>
            <strong className="font-medium text-lg">{discount}%</strong>
          </div>
          <div className="flex justify-between border-t py-2">
            <span className="font-light text-gray-600">Total</span>
            <strong className="flex flex-col items-end font-semibold text-[#244d54] text-lg">
              {Intl.NumberFormat('pt-AO', {
                style: 'currency',
                currency: 'AOA',
              }).format(price)}

              <span className="text-xs font-light text-slate-800">
                preço com IVA incluído à taxa em vigor
              </span>
            </strong>
          </div>
          <div className="flex justify-between border-t py-10">
            <ReCAPTCHA
              ref={referenceRecaptcha}
              sitekey={API_RECAPTCHA}
              onChange={onChangeRecaptcha}
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={isSubmitting && capValue !== null}
        className="bg-gray-800 w-full mt-8 py-2 rounded text-white font-normal text-sm hover:bg-gray-900 disabled:bg-gray-600 disabled:cursor-not-allowed"
      >
        {isSubmitting && capValue !== null
          ? 'Enviando...'
          : 'Finalizar encomenda'}
      </button>
      <button
        type="button"
        onClick={onPrevious}
        className="bg-gray-400 w-full mt-2 py-2 rounded text-white font-normal text-sm hover:bg-gray-500"
      >
        Voltar
      </button>
    </form>
  )
}
