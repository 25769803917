import { InputHTMLAttributes, forwardRef } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  idFor: string
  label: string
  placeholder?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, placeholder, idFor, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-2">
        <label htmlFor={idFor} className="text-sm text-gray-600">
          {label}
        </label>
        <input
          id={idFor}
          placeholder={placeholder}
          ref={ref}
          {...props}
          className="border rounded-lg px-4 py-2 text-sm outline-none focus:border-blue-300"
        />
      </div>
    )
  },
)

Input.displayName = ''
