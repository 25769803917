import androidTv from '../img/Png/android.png'
import tellas from '../img/Png/tellas.png'
import netflix from '../img/Png/netflix.png'
import youtube from '../img/Png/youtube.png'
import tvstore from '../img/SVG/tvstore.svg'
import hdr4k from '../img/Png/4k.png'
import screenSmart from '../img/Png/screen-smart-tv.png'
import controlBanner from '../img/Png/hand.png'

import ramMemory from '../img/SVG/RAM.svg'
import romMemory from '../img/SVG/ROM.svg'
import hdmi from '../img/SVG/HDMI.svg'
import usb from '../img/SVG/USB.svg'
import vga from '../img/SVG/VGA.svg'
import rfin from '../img/SVG/RF.svg'
import audio from '../img/SVG/AUDIO.svg'
import rJ45 from '../img/SVG/RJ45.svg'
import coaxial from '../img/SVG/COAXIAL.svg'
import avin from '../img/SVG/3avin.svg'
import info from '../img/SVG/info.svg'

import { Hero } from '../components/Hero'

export function Home() {
  return (
    <>
      <Hero />

      <section className="mt-[-100px] lg:-mt-[200px]">
        <div className="border-b border-gray-100">
          <div className="m-auto max-w-7xl px-8 md:px-6 flex  gap-8 items-center lg:flex-col lg:items-center flex-wrap justify-center">
            <div className="text-center">
              <strong className="text-2xl text-slate-700  leading-relaxed">
                Imagem e realidade
              </strong>
              <p className="text-2xl text-slate-700 leading-relaxed">
                nunca foram tão semelhantes
              </p>
            </div>
            <div className="bg-white shadow-xl rounded-2xl grid grid-cols-3 gap-8 items-center overflow-hidden p-4">
              <img src={androidTv} alt="" className="w-28" />
              <img src={tellas} alt="" className="w-28" />
              <img src={netflix} alt="" className="w-28" />
              <img src={youtube} alt="" className="w-28" />
              <img src={tvstore} alt="" className="w-28" />
              <img src={hdr4k} alt="" className="w-28" />
            </div>
            <div className="bg-[url(./img/Png/ellipse.png)] bg-no-repeat bg-contain bg-center">
              <img src={controlBanner} alt="" className="w-[500px]" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="mx-auto max-w-7xl px-8 md:px-6">
          <div className="grid lg:grid-cols-2 gap-20 md:grid-cols-1 items-center">
            <div>
              <img src={androidTv} alt="" className="w-80 mb-4" />
              <span className="font-extralight">
                Uma maneira mais fácil de desfrutar do entretenimento que você
                ama. Assista a partir dos seus aplicativos favoritos, reproduza
                músicas e jogos, a partir de qualquer dispositivo e muito mais.
              </span>

              <div className="grid grid-cols-4 gap-8 mt-8">
                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={ramMemory} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    RAM 1.5 GB
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={romMemory} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    8GB 2.4G
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={info} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    SUPORTA 802 11GB
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={hdmi} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    INTERFACE HDMI 2.0
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={avin} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    3 AV IN
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={usb} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    2X USB
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={vga} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    2X VGA
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={rfin} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    1X RF IN
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={audio} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    PC AUDIO IN AND 1X LINE OUT
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={coaxial} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    COAXIAL
                  </strong>
                </div>

                <div className="flex flex-col justify-center items-center gap-3">
                  <img src={rJ45} alt="" className="w-[80px]" />
                  <strong className="text-sm text-[#abd9e4] text-center">
                    RJ45
                  </strong>
                </div>
              </div>
            </div>

            <div>
              <img src={screenSmart} alt="Captura de ecrã da TV" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
