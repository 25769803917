import { Link } from 'react-router-dom'
import tv from '../img/Png/tv-hardware.png'
import { ShoppingBagOpen } from 'phosphor-react'
import { ENVIRONMENTS } from '../utils/app.config'
import { useEffect, useState } from 'react';

import { Modal } from './Modal';


export function Hero() {
  const [open, setOpen] = useState(true);

  const onCloseModal = () => setOpen(false);
  const IS_PROMOTION = process.env.REACT_APP_API_IS_PROMOTION === 'true'

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <section id="#" className="lg:h-screen lg:mb-0 mb-32">
      { open && IS_PROMOTION ? 
       <Modal handleCloseModal={onCloseModal} /> : null}
      <div className="h-[60%] bg-[url(./img/Png/background-noah.png)] bg-no-repeat bg-cover bg-center">
        <div className="mx-auto max-w-7xl px-8 md:px-6 h-full">
          <div className="grid grid-cols-1 justify-center lg:grid-cols-2 gap-4 lg:items-center">
            <div className="lg:pt-8 pt-20 flex flex-col items-center lg:items-stretch">
              <h1 className=" text-gray-50 font-bold text-center lg:text-left lg:text-8xl text-4xl">
                Genesis
              </h1>
              <span className="text-gray-50 font-bold uppercase text-center lg:text-left md:text-4xl text-2xl">
                a primeira
              </span>
              <span className="text-gray-50 md:text-4xl text-2xl text-center lg:text-left">
                55 UHD 4K
              </span>
              <p className="text-gray-50 md:text-4xl text-2xl font-bold text-center lg:text-left">
                TV SEM BORDAS - <span className="font-normal">DLED</span>
              </p>

              <Link
                to={`${ENVIRONMENTS === 'PROD' ? '/orders' : '/orders'}`}
                className="flex items-center justify-center gap-2  w-[80%] md:w-[50%] lg:w-[40%] mt-8 rounded-full bg-[#b0d1d7] py-4 text-center hover:bg-[#94b2b8] shadow-sm my-8"
              >
                Encomendar
                <ShoppingBagOpen size={24} />
              </Link>
            </div>
            <div className="flex justify-center mt-0 lg:mt-[200px]">
              <img src={tv} alt="" className="w-[400px] lg:w-[600px]" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
