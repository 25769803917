import { Link } from 'react-router-dom'
import logoNoah from '../img/noahlogo.png'

export function Header() {
  return (
    <header className="bg-black/90 min-w-full backdrop-blur absolute sticky top-0 left-0 z-50">
      <div className="mx-auto h-[70px] max-w-7xl px-8 md:px-6 py-3">
        <Link to="/">
          <img src={logoNoah} alt="" className="w-32 mx-auto" />
        </Link>
      </div>
    </header>
  )
}
